<header class="pt-2 pb-2 pr-4 pl-4">
  <div class="flex justify-content-between align-items-center">
    <div>
      <a href="#" routerLink="/portal/dashboard" (click)="goToDashboard()">
        <img src="https://shopnomad.syd1.cdn.digitaloceanspaces.com/global/assets/brand/logo-mark.png" height="30" class="mt-2">
      </a>
    </div>
    <div class="flex-grow-1 flex justify-content-end align-items-center">
      <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input id="search" type="text" placeholder="Search" class="text-sm mr-2" pInputText />
      </span>
      <a href="#" [routerLink]="[]" class="se-header-icon mr-3" pTooltip="Notifications" tooltipPosition="bottom">
        <i class="fa-solid fa-bell"></i>
      </a>
      <div class="relative">
        <a href="#" [routerLink]="[]" (click)="toggleBag()" class="se-header-icon mr-3" pTooltip="My Bag" tooltipPosition="bottom" *ngIf="countItemsInBag() === 0">
          <i class="fa-solid fa-bag-shopping"></i>
        </a>
        <a href="#" [routerLink]="[]" (click)="toggleBag()" class="se-header-icon mr-3" pBadge [value]="'' + countItemsInBag()" severity="info" pTooltip="My Bag" tooltipPosition="bottom" *ngIf="countItemsInBag() > 0">
          <i class="fa-solid fa-bag-shopping"></i>
        </a>
        <div class="custom-dropdown bag" *ngIf="_showBag">
          <div class="p-2">
            <div class="text-xs text-center" *ngIf="!_bagItems || _bagItems.length === 0">
              <div class="text-gray p-3">You don't have any items in your bag.</div>
            </div>
            <div *ngIf="_bagItems">
              <div class="bag-item overflow-hidden" *ngFor="let item of _bagItems">
                <div class="flex align-items-center">
                  <div class="col-2">
                    <img [src]="item.photo_url" />
                  </div>
                  <div class="col-7">
                    <h4 class="mt-0 mb-0 text-ellipsis">{{item.name}}</h4>
                    <small class="text-gray">Qty: {{item.quantity}}</small>
                  </div>
                  <div class="col-3 text-right">
                    <div class="text-sm">{{item.effective_price * item.quantity | currency: _selectedCurrency}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer text-right p-2">
            <div class="text-sm mb-2">
              <div class="flex justify-content-between ">
                <div>Discounts Applied</div>
                <div>{{0 | currency: _selectedCurrency}}</div>
              </div>
              <div class="flex justify-content-between" *ngIf="_selectedCurrency">
                <div>Total Amount</div>
                <div class="font-bold">{{_bag?.total_effective_amount | currency: _selectedCurrency}}</div>
              </div>
            </div>
            <button pButton pRipple type="button" label="Checkout" class="primary p-button-sm"></button>
            <button pButton pRipple type="button" label="Clear" (click)="clearBag()" class="btn-clear p-button-sm ml-2" *ngIf="_shops.length > 0"></button>
          </div>
        </div>
      </div>
      <div class="relative">
        <a href="#" [routerLink]="[]" (click)="toggleShopList()" class="se-header-icon mr-3" pTooltip="My Stores" tooltipPosition="bottom">
          <i class="fa-solid fa-store"></i>
        </a>
        <div class="custom-dropdown" *ngIf="_showShopMenu">
          <div class="pt-2 pb-2">
            <div class="text-xs text-center" *ngIf="_shops.length === 0">
              <div class="text-gray p-3">You haven't opened any shops.</div>
            </div>
            <div class="text-xs text-center" *ngIf="_shops.length > 0">
              <div *ngFor="let shop of _shops">
                <a href="#" [routerLink]="[]" (click)="switchShop(shop)" class="flex align-items-center shop p-2">
                  <div class="flex align-items-center p-2">
                    <img src="https://shopnomad.syd1.cdn.digitaloceanspaces.com/global/assets/brand/logo-mark.png" width="25" height="25" *ngIf="!shop.logo?.url">
                  </div>
                  <h3>{{shop.name}}</h3>
                </a>
              </div>
            </div>
          </div>
          <div class="footer text-right p-2">
            <button pButton pRipple type="button" (click)="openShop()" label="Open shop" class="primary p-button-sm"></button>
            <button pButton pRipple type="button" (click)="openShop()" label="View all" class="btn-clear p-button-sm ml-2" *ngIf="_shops.length > 0"></button>
          </div>
        </div>
      </div>
      <p-menu #userMenu [popup]="true" [model]="_userMenuItems"></p-menu>
      <a href="#" [routerLink]="" (click)="userMenu.toggle($event); hideShopList();" class="se-header-icon">
        <i class="fa-regular fa-user-circle"></i>
      </a>
    </div>
  </div>
</header>
<p-dialog [(visible)]="_showOpenShop" [modal]="true" [draggable]="false" [style]="{'width': '600px'}" [resizable]="false" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <h2><i class="fa-solid fa-shop text-primary mr-2"></i> Open Shop</h2>
    </div>
  </ng-template>
  <sh-open-shop (onCancel)="closeOpenShopDialog($event)"></sh-open-shop>
</p-dialog>
