import {Component, OnInit} from '@angular/core';
import {OAuthService} from "angular-oauth2-oidc";
import {authConfig} from "../../auth-config";
import {JwksValidationHandler} from "angular-oauth2-oidc-jwks";
import {MenuItem} from "primeng/api";
import {UserService} from "../../services/user.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {User} from "../../dto/user";
import {ShopService} from "../../services/shop.service";
import {Shop} from "../../dto/shop";
import {Router} from "@angular/router";
import {BreadcrumbService} from "../../services/breadcrumb.service";
import {Bag} from "../../dto/bag";
import {OrderService} from "../../services/order.service";
import {Currency} from "../../dto/currency";

@UntilDestroy()
@Component({
  selector: 'sh-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  _authenticated = false;
  _userMenuItems: MenuItem[] = [];

  _currentUserName: string = '';
  _currentUser: User | undefined;

  _shops: Shop[] = [];
  _showShopMenu: boolean = false;
  _showBag: boolean = false;
  _showOpenShop: boolean = false;

  _bag: Bag | undefined;
  _bagItems: any[] = [];

  _currencies: Currency[] = [];
  _selectedCurrency: string | undefined;

  constructor(private oauthService: OAuthService,
              private userService: UserService,
              private shopService: ShopService,
              private orderService: OrderService,
              private breadcrumbService: BreadcrumbService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.subscribeToCurrentUser();
    this.configureOAuth();
    this.subscribeToShopList();
    this.subscribeToBag();
    this.subscribeToCurrencies();
    this.orderService.loadUserBag();
    this.shopService.listActiveAffiliatedShops();
    this.shopService.listCurrencies();
    this._selectedCurrency = <string> sessionStorage.getItem('currency');
  }

  goToDashboard(): void {
    sessionStorage.removeItem('currentShop');
    sessionStorage.removeItem('currentShopName');
    this.breadcrumbService.reset();
  }

  switchShop(shop: Shop): void {
    this.router.navigateByUrl('/portal/' + shop.reference, {skipLocationChange: true})
      .then(() => this.router.navigateByUrl('/portal/' + shop.reference));
    this.breadcrumbService.reset();
  }

  logout(): void {
    this.oauthService.logOut();
  }

  toggleShopList(): void {
    this._showShopMenu = !this._showShopMenu;
    if (this._showShopMenu) {
      this._showBag = false;
    }
  }

  hideShopList(): void {
    this._showShopMenu = false;
  }

  toggleBag(): void {
    this._showBag = !this._showBag;
    if (this._showBag) {
      this._showShopMenu = false;
    }
  }

  hideBag(): void {
    this._showBag = false;
  }

  countItemsInBag(): number {
    let itemCount = 0;
    if (this._bagItems) {
      this._bagItems.forEach(item => itemCount += item.quantity);
    }

    return itemCount;
  }

  clearBag(): void {
    this.orderService.clearBag();
  }

  openShop(): void {
    this._showOpenShop = true;
  }

  closeOpenShopDialog($event: any): void {
    this._showOpenShop = false;
    this.shopService.listActiveAffiliatedShops();
  }

  private initMenu(): void {
    this._userMenuItems = [
      {
        label: 'Account Settings',
        icon: 'fa-regular fa-id-card',
        routerLink: '/user/profile'
      },
      {
        label: 'My Orders',
        icon: 'fa-solid fa-file-invoice',
        routerLink: '/user/orders'
      },
      {
        label: 'My Wishlist',
        icon: 'fa-regular fa-heart',
        routerLink: '/user/wishlist'
      },
      {
        label: 'My Shops',
        icon: 'fa-solid fa-shop',
        routerLink: '/user/shops'
      },
      {
        label: 'Support',
        icon: 'fa-solid fa-circle-question '
      },
      {
        label: 'Sign out',
        icon: 'fa-solid fa-arrow-right-from-bracket',
        command: () => this.logout()
      }
    ];
  }

  private configureOAuth(): void {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        this.oauthService.setupAutomaticSilentRefresh({}, 'access_token');
        this._authenticated = this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
        this.initMenu();
        if (!this._authenticated) {
          this.logout();
        } else {
          this._currentUserName = this.oauthService.getIdentityClaims()['name'];
          sessionStorage.setItem('currentUser', this._currentUserName);
          // this.userService.loadCurrentUser();
        }
      });
  }

  private subscribeToCurrentUser(): void {
    this.userService.currentUser
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        this._currentUser = user;
      });
  }

  private subscribeToShopList() {
    this.shopService.shopsForUser
      .pipe(untilDestroyed(this))
      .subscribe(shops => {
        this._shops = shops;
      });
  }

  private subscribeToBag() {
    this.orderService.bag
      .pipe(untilDestroyed(this))
      .subscribe(bag => {
        this._bag = bag;
        if (this._bag) {
          this._bagItems = this._bag?.items;
        }
      });
  }

  private subscribeToCurrencies(): void {
    this.shopService.currencies
      .pipe(untilDestroyed(this))
      .subscribe(currencies => {
        this._currencies = currencies;
      });
  }
}
