import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {OAuthService} from "angular-oauth2-oidc";
import {authConfig} from "./auth-config";
import {JwksValidationHandler} from "angular-oauth2-oidc-jwks";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private oauthService: OAuthService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return this.oAuthService.loadDiscoveryDocument()
    //   .then(() => this.oAuthService.tryLogin())
    //   .then(() => {
    //     let hasIdToken = this.oAuthService.hasValidIdToken();
    //     let hasAccessToken = this.oAuthService.hasValidAccessToken();
    //     console.log('has id token', this.oAuthService.clientId, hasIdToken, hasAccessToken);
    //     if (hasIdToken && hasAccessToken) {
    //       return true;
    //     } else {
    //       this.router.navigateByUrl('');
    //       return false;
    //     }
    //   });
    // return this.authService.isDoneLoading$.pipe(
    //   filter(isDone => isDone),
    //   switchMap(_ => this.authService.isAuthenticated$),
    //   tap(isAuthenticated => isAuthenticated || this.authService.login(state.url)),
    // );

    let hasIdToken = this.oauthService.hasValidIdToken();
    let hasAccessToken = this.oauthService.hasValidAccessToken();
    if (hasIdToken && hasAccessToken) {
      return true;
    } else {
      this.router.navigateByUrl('');
      return false;
    }

    // return true;
  }
}
