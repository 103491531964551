import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ApiEventService} from "../../services/api-event.service";
import {ApiEventStatus} from "../../dto/enums/api-event-status";
import {MessageService} from "primeng/api";
import {ApiEventType} from "../../dto/enums/api-event-type";

@UntilDestroy()
@Component({
  selector: 'sh-api-event-toaster',
  templateUrl: './api-event-toaster.component.html',
  styleUrls: ['./api-event-toaster.component.less']
})
export class ApiEventToasterComponent implements OnInit {

  private readonly _supportedEventTypes: ApiEventType[] = [
    ApiEventType.OPEN_SHOP,
    ApiEventType.UPDATE_SHOP,
    ApiEventType.ADD_PRODUCT,
    ApiEventType.CREATE_SUPPLY,
    ApiEventType.CREATE_SUPPLY_GROUP,
    ApiEventType.CREATE_INVENTORY,
  ]

  constructor(private apiEventService: ApiEventService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.subscribeToApiEvents();
  }

  private subscribeToApiEvents(): void {
    this.apiEventService.event
      .pipe(untilDestroyed(this))
      .subscribe(event => {
        if (event && this._supportedEventTypes.includes(<ApiEventType> event.type)) {
          if (event.status === ApiEventStatus.COMPLETED) {
            this.messageService.add({severity: 'success', summary: this.getSuccessSummary(event.type), detail: event.message});
          } else if (event.status === ApiEventStatus.ERROR) {
            this.messageService.add({severity: 'danger', summary: this.getErrorSummary(event.type), detail: event.message, sticky: true});
          }
        }
      });
  }

  private getSuccessSummary(type?: ApiEventType): string | undefined {
    if (type) {
      switch (type) {
        case ApiEventType.OPEN_SHOP: return "New shop opened";
        case ApiEventType.UPDATE_SHOP: return "Shop profile updated";
        case ApiEventType.ADD_PRODUCT: return "New shop item";
        case ApiEventType.CREATE_SUPPLY: return "New supply added";
        case ApiEventType.CREATE_SUPPLY_GROUP: return "New custom supply added";
        case ApiEventType.CREATE_INVENTORY: return "New inventory added";
      }
    }
    return undefined;
  }

  private getErrorSummary(type?: ApiEventType): string {
    if (type) {
      switch (type) {
        case ApiEventType.OPEN_SHOP: return "Failed to open shop";
        case ApiEventType.UPDATE_SHOP: return "Failed to update shop profile";
        case ApiEventType.ADD_PRODUCT: return "Failed to add shop item";
        case ApiEventType.CREATE_SUPPLY: return "Failed to add supply";
        case ApiEventType.CREATE_SUPPLY_GROUP: return "Failed to add custom supply";
        case ApiEventType.CREATE_INVENTORY: return "Failed to create inventory";
      }
    }
    return 'An error occurred';
  }
}
