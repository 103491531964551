import {Component, Input, OnInit} from '@angular/core';
import {OAuthService} from "angular-oauth2-oidc";

@Component({
  selector: 'sh-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {

  @Input()
  _showPersonnelInfo: boolean | undefined;

  _currentUser: string | undefined;

  constructor(private oauthService: OAuthService) {
  }

  ngOnInit(): void {
    this._currentUser = this.oauthService.getIdentityClaims()['name'];
  }
}
