import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ApiEventService} from "./api-event.service";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, catchError, Observable, throwError} from "rxjs";
import {Bag} from "../dto/bag";
import {ApiEventType} from "../dto/enums/api-event-type";
import {ApiEventStatus} from "../dto/enums/api-event-status";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Order} from "../dto/order";
import {PagedData} from "../dto/paged-data";
import {OrderSummary} from "../dto/order-summary";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private _ordersApi = environment.api.orders;

  private _bag = new BehaviorSubject<Bag | undefined>(undefined);
  private _orders = new BehaviorSubject<PagedData<Order> | undefined>(undefined);
  private _orderSummary = new BehaviorSubject<OrderSummary | undefined>(undefined);

  constructor(private http: HttpClient,
              private apiEventService: ApiEventService) { }

  get bag(): Observable<Bag | undefined> {
    return this._bag.asObservable();
  }

  get orders(): Observable<PagedData<Order>> {
    return <Observable<PagedData<Order>>> this._orders.asObservable();
  }

  get orderSummary(): Observable<OrderSummary | undefined> {
    return this._orderSummary.asObservable();
  }

  loadUserBag(): void {
    this.apiEventService.publish({type: ApiEventType.LOAD_BAG, status: ApiEventStatus.IN_PROGRESS});
    this.http.get(this._ordersApi + '/bag')
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.LOAD_BAG)))
      .subscribe((response: any) => {
        this._bag.next(response);
        this.apiEventService.publish({type: ApiEventType.LOAD_BAG, status: ApiEventStatus.COMPLETED});
      });
  }

  addToBag(shopItemId: number) {
    this.apiEventService.publish({type: ApiEventType.UPDATE_BAG, status: ApiEventStatus.IN_PROGRESS});
    this.http.post(this._ordersApi + '/add-to-bag/' + shopItemId, {})
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.UPDATE_BAG)))
      .subscribe((response: any) => {
        this.apiEventService.publish({type: ApiEventType.UPDATE_BAG, status: ApiEventStatus.COMPLETED});
      });
  }

  clearBag() {
    this.apiEventService.publish({type: ApiEventType.UPDATE_BAG, status: ApiEventStatus.IN_PROGRESS});
    this.http.put(this._ordersApi + '/clear-bag', {})
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.UPDATE_BAG)))
      .subscribe((response: any) => {
        this.apiEventService.publish({type: ApiEventType.UPDATE_BAG, status: ApiEventStatus.COMPLETED});
      });
  }

  createShopOrder(request: any): void {
    this.apiEventService.publish({type: ApiEventType.CREATE_SHOP_ORDER, status: ApiEventStatus.IN_PROGRESS});
    this.http.post(this._ordersApi + '/shop', request)
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.CREATE_SHOP_ORDER)))
      .subscribe((response: any) => {
        this.apiEventService.publish({type: ApiEventType.CREATE_SHOP_ORDER, status: ApiEventStatus.COMPLETED});
      });
  }

  listShopOrders(shopReference: string, currency: string, page: number, itemsPerPage: number, from?: string, until?: string): void {
    this.apiEventService.publish({type: ApiEventType.LIST_SHOP_ORDERS, status: ApiEventStatus.IN_PROGRESS});
    let dateRangeParam = '';
    if (from && until) {
      dateRangeParam += '&from=' + from + '&until=' + until;
    }
    this.http.get(this._ordersApi + '?shopReference=' + shopReference + '&symbol=' + currency + dateRangeParam + '&pageNumber=' + page + '&pageSize=' + itemsPerPage)
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.LIST_SHOP_ORDERS)))
      .subscribe((response: any) => {
        this._orders.next(response);
        this.apiEventService.publish({type: ApiEventType.LIST_SHOP_ORDERS, status: ApiEventStatus.COMPLETED});
      });
  }

  loadOrderSummary(shopReference: string, currency: string, from?: string, until?: string): void {
    this.apiEventService.publish({type: ApiEventType.LOAD_ORDER_SUMMARY, status: ApiEventStatus.IN_PROGRESS});
    let dateRangeParam = '';
    if (from && until) {
      dateRangeParam += '&from=' + from + '&until=' + until;
    }
    this.http.get(this._ordersApi + '/summary?shopReference=' + shopReference + '&symbol=' + currency + dateRangeParam)
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.LOAD_ORDER_SUMMARY)))
      .subscribe((response: any) => {
        this._orderSummary.next(response);
        this.apiEventService.publish({type: ApiEventType.LOAD_ORDER_SUMMARY, status: ApiEventStatus.COMPLETED});
      });
  }

  private handleError(error: HttpErrorResponse, type: ApiEventType) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    }
    if (error.error?.code === 'CONSTRAINT_VIOLATION') {
      this.apiEventService.publish({ type: type, status: ApiEventStatus.ERROR, message: 'Request contains missing required field or data with incorrect format'});
    } else {
      this.apiEventService.publish({ type: type, status: ApiEventStatus.ERROR, message: error.error?.message});
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('An unexpected error occurred; please try again later.'));
  }
}
