export enum ApiEventType {
  LOAD_CURRENT_USER,
  OPEN_SHOP,
  LIST_SHOPS,
  LIST_ACTIVE_SHOPS,
  LIST_AFFILIATED_SHOPS,
  LIST_ACTIVE_AFFILIATED_SHOPS,
  LIST_CURRENCIES,
  LIST_SHOP_CURRENCIES,
  SELECT_SHOP,
  UPDATE_SHOP,
  CREATE_SHOP_ORDER,
  LIST_SHOP_ORDERS,
  LOAD_ORDER_SUMMARY,
  CREATE_SUPPLY,
  CREATE_SUPPLY_GROUP,
  CREATE_INVENTORY,
  LIST_INVENTORY,
  LIST_SUPPLIES,
  LIST_SUPPLY_SUMMARY,
  SEARCH_SUPPLIES,
  SEARCH_SHOP_ITEM,
  LIST_SHOP_ITEMS,
  LIST_SHOP_ITEM_TYPES,
  LIST_ALL_ITEM_TYPES,
  ADD_PRODUCT,
  LOAD_BAG,
  UPDATE_BAG,
}
