export const environment = {
  production: false,
  auth: {
    issuer: 'https://secure-dev.shopnomad.app/realms/shopnomad',
    clientId: 'shopnomad-web',
    redirectUri: window.location.origin,
    scope: 'openid profile email offline_access',
    showDebugInformation: false
  },
  api: {
    public: 'https://dev-api.shopnomad.app/api/public',
    shops: 'https://dev-api.shopnomad.app/api/shops',
    users: 'https://dev-api.shopnomad.app/api/users',
    shopItems: 'https://dev-api.shopnomad.app/api/shop-items',
    orders: 'https://dev-api.shopnomad.app/api/orders',
    inventory: 'https://dev-api.shopnomad.app/api/inventory'
  }
};
