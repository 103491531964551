<p-steps [model]="_steps" [activeIndex]="_currentStep" [readonly]="true" styleClass="p-0"></p-steps>
<form class="mt-4" [formGroup]="_newShopForm" (submit)="submit()" *ngIf="_newShopForm">
  <div *ngIf="_currentStep == 0">
    <div class="field">
      <span class="p-float-label mt-2">
        <input type="text" id="name" formControlName="shopName" class="w-100" required pInputText>
        <label for="name">Name your shop <span class="required">*</span></label>
      </span>
    </div>
    <div class="field">
      <span class="p-float-label mt-4">
        <textarea pInputTextarea id="description" formControlName="description" class="w-100" rows="4" cols="30"></textarea>
        <label for="description">How would you describe your shop?</label>
      </span>
    </div>
    <p-dropdown inputId="currency" [options]="_currencies" formControlName="currency" placeholder="Select your primary currency" optionLabel="name" styleClass="w-100">
      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="_newShopForm.controls['currency'].value">
          <img [src]="_newShopForm.controls['currency'].value.icon_url" class="mr-2" style="width: 18px"/>
          <div>{{ _newShopForm.controls['currency'].value.name }}</div>
        </div>
      </ng-template>
      <ng-template let-currency pTemplate="item">
        <div class="flex align-items-center gap-2">
          <img [src]="currency.icon_url" class="mr-2" style="width: 18px"/>
          <div>{{ currency.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div *ngIf="_currentStep == 1">
    <p>What type of products will you be selling?</p>
    <small class="text-gray">Select at least one.</small>
    <div class="mt-4">
      <div class="p-2" *ngFor="let type of _itemTypes">
        <p-checkbox name="itemTypes" formControlName="types" (onChange)="onTypeSelect($event, type)"  [value]="type.key" inputId="{{type.key}}" class="mr-2"></p-checkbox>
        <label>{{type.name}}</label>
      </div>
    </div>
    <div id="item-type-info-container" class="light-gray-panel mt-4">
      <small>If you prefer finer-grained classification, you can add your own custom types under each type above in your shop's Back Office anytime.</small>
    </div>
  </div>
  <div *ngIf="_currentStep == 2">
    <div class="row flex mt-2 mb-2">
      <div class="col-3 font-bold">Shop name:</div>
      <div class="col-9">{{_newShopForm.controls['shopName'].value}}</div>
    </div>
    <div class="row flex mt-2 mb-2">
      <div class="col-3 font-bold">Description:</div>
      <div class="col-9">{{_newShopForm.controls['description'].value}}</div>
    </div>
    <div class="row flex mt-2 mb-2">
      <div class="col-3 font-bold">Currency:</div>
      <div class="col-9">
        <img [src]="_newShopForm.controls['currency'].value?.icon_url" class="mr-2" style="width: 18px"/>{{_newShopForm.controls['currency'].value?.name}}
      </div>
    </div>
    <div class="row flex mt-2 mb-2">
      <div class="col-3 font-bold">Product types:</div>
      <div class="col-9">
        <div *ngFor="let type of _selectedTypes">{{type.name}}</div>
      </div>
    </div>
  </div>
  <div class="mt-6 text-center">
    <button pButton pRipple type="button" label="Next" (click)="next()" class="primary mr-2" *ngIf="_currentStep == 0" [disabled]="_newShopForm.invalid"></button>
    <button pButton pRipple type="button" label="Next" (click)="next()" class="primary mr-2" *ngIf="_currentStep == 1" [disabled]="_selectedTypes.length === 0"></button>
    <button pButton pRipple type="submit" label="Open Shop" class="primary mr-2" *ngIf="_currentStep == 2" [disabled]="_newShopForm.invalid"></button>
    <button pButton pRipple type="button" (click)="back()" label="Cancel" class="btn-clear" *ngIf="_currentStep == 0"></button>
    <button pButton pRipple type="button" (click)="back()" label="Back" class="btn-clear" *ngIf="_currentStep > 0"></button>
  </div>
</form>
