import {Component, Input, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ApiEventService} from "../../services/api-event.service";
import {ApiEventStatus} from "../../dto/enums/api-event-status";

@UntilDestroy()
@Component({
  selector: 'sh-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.less']
})
export class SpinnerComponent implements OnInit {

  @Input()
  _showSpinner: boolean | undefined;

  constructor(private apiEventService: ApiEventService) {
  }

  ngOnInit(): void {
    this.subscribeToApiEvents();
  }

  private subscribeToApiEvents(): void {
    this.apiEventService.event
      .pipe(untilDestroyed(this))
      .subscribe(event => {
        this._showSpinner = event && event.status === ApiEventStatus.IN_PROGRESS;
      });
  }
}
