import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ShopService} from "../../services/shop.service";
import {ApiEventService} from "../../services/api-event.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ApiEventType} from "../../dto/enums/api-event-type";
import {ApiEventStatus} from "../../dto/enums/api-event-status";
import {Currency} from "../../dto/currency";
import {MenuItem} from "primeng/api";
import {ShopItemService} from "../../services/shop-item.service";
import {EnumData} from "../../dto/enum-data";

@UntilDestroy()
@Component({
  selector: 'sh-open-shop',
  templateUrl: './open-shop.component.html',
  styleUrls: ['./open-shop.component.less']
})
export class OpenShopComponent implements OnInit {

  @Output()
  onCancel = new EventEmitter();

  _newShopForm: FormGroup | undefined;
  _currentStep = 0;
  _currencies: Currency[] = [];
  _itemTypes: EnumData[] = [];
  _selectedTypes: EnumData[] = [];

  _steps: MenuItem[] = [
    {
      label: 'Shop Information'
    },
    {
      label: 'Products'
    },
    {
      label: 'Review Details'
    }
  ];

  constructor(private shopService: ShopService,
              private shopItemService: ShopItemService,
              private apiEventService: ApiEventService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.subscribeToApiEvents();
    this.subscribeToCurrencies();
    this.subscribeToAllItemTypes();
    this.initForm();
    this.shopItemService.listAllTypes('FOOD');
  }

  next(): void {
    this._currentStep += 1;
    if (this._currentStep == 2) {
      this._newShopForm?.get('types')?.setValue(this._selectedTypes.map(type => type.key));
    }
  }

  back(): void {
    if (this._currentStep == 0) {
      this.cancel();
    } else {
      this._currentStep -= 1;
    }
  }

  submit(): void {
    if (this._newShopForm?.valid) {
      this.shopService.openNewShop({
        name: this._newShopForm.get('shopName')?.value,
        description: this._newShopForm.get('description')?.value,
        currency: this._newShopForm.get('currency')?.value.symbol,
        item_types: this._newShopForm?.get('types')?.value
      });
    }
  }

  onTypeSelect(event : any, type: EnumData) {
    if(event.checked && this._selectedTypes.findIndex(selected => selected.key === type.key) == -1) {
      this._selectedTypes.push(type);
    } else if(this._selectedTypes.findIndex(selected => selected.key === type.key) > -1) {
      this._selectedTypes.splice(this._selectedTypes.findIndex(selected => selected.key == type.key), 1);
    }
  }

  private cancel(): void {
    this.initForm();
    this._currentStep = 0;
    this._selectedTypes = [];
    this.onCancel.emit(true);
  }

  private initForm(): void {
    this._newShopForm = this.formBuilder.group({
      shopName: this.formBuilder.control('', Validators.required),
      description: this.formBuilder.control(''),
      currency: this.formBuilder.control('', Validators.required),
      types: this.formBuilder.control([])
    });
  }

  private subscribeToApiEvents(): void {
    this.apiEventService.event
      .pipe(untilDestroyed(this))
      .subscribe(event => {
        if (event?.type === ApiEventType.OPEN_SHOP && event?.status === ApiEventStatus.COMPLETED) {
          this.cancel();
        }
      });
  }

  private subscribeToCurrencies(): void {
    this.shopService.currencies
      .pipe(untilDestroyed(this))
      .subscribe(currencies => {
        this._currencies = currencies;
      });
  }

  private subscribeToAllItemTypes(): void {
    this.shopItemService.itemTypes
      .pipe(untilDestroyed(this))
      .subscribe(types => {
        this._itemTypes = types;
      });
  }
}
