<p-dataView #shopItems [value]="_shopItems" [paginator]="true" [rows]="24" filterBy="name"
            [layout]="_fixedLayout || _shopItemLayout">
  <ng-template pTemplate="header" *ngIf="_header || !_fixedLayout">
    <div class="flex justify-content-between align-items-center">
      <div>
        <h3 class="mb-0">{{_header}}</h3>
        <small class="text-gray">{{_headerSubtitle}}</small>
      </div>
      <p-dataViewLayoutOptions *ngIf="!_fixedLayout"></p-dataViewLayoutOptions>
    </div>
  </ng-template>
  <ng-template let-item pTemplate="listItem">
    <div class="p-card w-100 border-0">
      <div class="p-card-body flex align-items-center">
        <div class="relative" *ngIf="item.selected_photo else noImage">
          <img class="product-photo list" [src]="item.selected_photo?.photo?.url" [alt]="item.name"/>
          <div class="product-sticker">
            <div class="best-seller mt-0 mb-0 font-bold" *ngIf="item.best_seller">Best Seller</div>
            <div class="instore-only mt-0 mb-0 font-bold" *ngIf="!item.shop?.delivery_enabled && item.shop?.instore_enabled">In-Store</div>
          </div>
          <div class="flex w-100">
            <div class="col-3 md:col-2 flex p-0" *ngFor="let photo of item.featured_photos">
              <img class="product-thumbnail list flex-grow-1" [src]="photo.photo.url" [alt]="item.name" (click)="previewPhoto(item, photo)" [ngClass]="{'active': photo === item.selected_photo}" />
            </div>
          </div>
        </div>
        <div class="product-content-wrapper pl-3">
          <h3 class="mt-0 mb-0">{{item.name}}</h3>
          <div>
            <i class="fa-solid text-primary" *ngFor="let i of countStars(item)" [ngClass]="{'fa-star': i === 1, 'fa-star-half': i < 1}"></i>{{item.rating | number : '1.1-1'}}
          </div>
          <small class="text-gray" *ngIf="_shop">Stocks left: 20</small>
          <p class="product-description text-ellipsis-3 text-sm">{{item.description}}</p>
        </div>
        <div class="product-controls-wrapper text-right ml-3">
          <h3 class="mt-0">{{getPrice(item) | currency: _currency}}</h3>
          <!-- TODO: Replace with RBAC once implemented -->
          <div class="flex flex-column" *ngIf="_showControls">
            <button pButton pRipple type="button" label="Add to Bag" icon="fa-solid fa-bag-shopping" (click)="addToBag(item)" class="primary mb-2" *ngIf="!_shop"></button>
            <button pButton pRipple type="button" label="Add to Wishlist" icon="fa-regular fa-heart" class="btn-clear" *ngIf="!_shop"></button>
            <button pButton pRipple type="button" label="Edit Item" icon="fa-solid fa-pencil" class="primary" *ngIf="_shop"></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template let-item pTemplate="gridItem">
    <div class="col-12 md:col-2">
      <div class="p-card m-1 border-0">
        <div class="p-card-title p-2 mb-0">
          <div class="relative" *ngIf="item.selected_photo else noImage">
            <img class="product-photo" [src]="item.selected_photo?.photo?.url" [alt]="item.name"/>
            <div class="product-sticker">
              <h5 class="price mt-0 mb-0">{{getPrice(item) | currency: _currency}}</h5>
              <div class="best-seller mt-0 mb-0" *ngIf="item.best_seller">Best Seller</div>
              <div class="instore-only mt-0 mb-0" *ngIf="!item.shop?.delivery_enabled && item.shop?.instore_enabled">In-Store</div>
            </div>
            <div class="flex w-100">
              <div class="col-3 md:col-2 p-0" *ngFor="let photo of item.featured_photos">
                <img class="product-thumbnail" [src]="photo.photo.url" [alt]="item.name" (click)="previewPhoto(item, photo)" [ngClass]="{'active': photo === item.selected_photo}"/>
              </div>
            </div>
          </div>
        </div>
        <div class="p-card-body">
          <div class="flex justify-content-between">
            <div class="pl-2">
              <h3 class="mt-0 mb-0">{{item.name}}</h3>
              <div>
                <i class="fa-solid text-primary" *ngFor="let i of countStars(item)" [ngClass]="{'fa-star': i === 1, 'fa-star-half': i < 1}"></i>{{item.rating | number : '1.1-1'}}
              </div>
              <small class="text-gray" *ngIf="_shop">Stocks left: 20</small>
              <p class="product-description text-ellipsis-3 text-sm" *ngIf="_shop">{{item.description}}</p>
            </div>
          </div>
          <div class="flex mt-3" *ngIf="_showControls">
            <!-- TODO: Replace with RBAC once implemented -->
            <div class="col-6 pl-0" *ngIf="!_shop">
              <button pButton pRipple type="button" icon="fa-solid fa-bag-shopping" (click)="addToBag(item)" class="primary mr-2 w-100" pTooltip="Add to Bag"></button>
            </div>
            <div class="col-6 pr-0" *ngIf="!_shop">
              <button pButton pRipple type="button" icon="fa-regular fa-heart" class="btn-clear w-100" pTooltip="Add to Wishlist"></button>
            </div>
            <button pButton pRipple type="button" label="Edit" icon="fa-solid fa-pencil" class="primary w-100" *ngIf="_shop"></button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="empty">
    <div class="text-center">
      <p class="text-gray">No items to show.</p>
    </div>
  </ng-template>
</p-dataView>
<ng-template #noImage>
  <div class="flex justify-content-center align-items-center">
    <img src="https://shopnomad.syd1.cdn.digitaloceanspaces.com/global/assets/brand/logo-mark.png" width="60" height="60" class="mr-2">
  </div>
</ng-template>
