import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {ApiEventService} from "./api-event.service";
import {ApiEventType} from "../dto/enums/api-event-type";
import {ApiEventStatus} from "../dto/enums/api-event-status";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {BehaviorSubject, catchError, Observable, throwError} from "rxjs";
import {ShopItem} from "../dto/shop-item";
import {ShopItemType} from "../dto/shop-item-type";
import {PagedData} from "../dto/paged-data";
import {EnumData} from "../dto/enum-data";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ShopItemService {

  private _shopItemsApi = environment.api.shopItems;
  private _publicApi = environment.api.public;
  // @ts-ignore
  private _shopItems = new BehaviorSubject<PagedData<ShopItem>>(undefined);
  private _shopItemTypes = new BehaviorSubject<ShopItemType[]>([]);
  private _itemTypes = new BehaviorSubject<EnumData[]>([]);
  private _searchResults = new BehaviorSubject<ShopItem[]>([]);
  constructor(private http: HttpClient,
              private apiEventService: ApiEventService) { }

  get shopItems(): Observable<PagedData<ShopItem>> {
    return this._shopItems.asObservable();
  }

  get shopItemTypes(): Observable<ShopItemType[]> {
    return this._shopItemTypes.asObservable();
  }

  get itemTypes(): Observable<EnumData[]> {
    return this._itemTypes.asObservable();
  }

  get searchResults(): Observable<ShopItem[]> {
    return this._searchResults.asObservable();
  }

  listItems(page: number, itemsPerPage: number, currency: string, shopReference?: string, loadSupplies?: boolean): void {
    this.apiEventService.publish({ type: ApiEventType.LIST_SHOP_ITEMS, status: ApiEventStatus.IN_PROGRESS });

    let queryParams = '?symbol=' + currency + '&pageNumber=' + page + '&pageSize=' + itemsPerPage;
    if (shopReference) {
      queryParams +=  ('&shopReference=' + shopReference);
    }
    if (loadSupplies !== undefined) {
      queryParams += ('&loadSupplies=' + loadSupplies);
    }
    this.http.get(this._shopItemsApi + queryParams)
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.LIST_SHOP_ITEMS)))
      .subscribe((response: any) => {
        this.apiEventService.publish({ type: ApiEventType.LIST_SHOP_ITEMS, status: ApiEventStatus.COMPLETED });
        this._shopItems.next(response);
      });
  }

  listAllTypes(module?: string): void {
    this.apiEventService.publish({ type: ApiEventType.LIST_ALL_ITEM_TYPES, status: ApiEventStatus.IN_PROGRESS });

    this.http.get(this._publicApi + '/modules/' + module + '/types')
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.LIST_ALL_ITEM_TYPES)))
      .subscribe((response: any) => {
        this.apiEventService.publish({ type: ApiEventType.LIST_ALL_ITEM_TYPES, status: ApiEventStatus.COMPLETED });
        this._itemTypes.next(response);
      });
  }

  listTypes(shopReference: string, module: string): void {
    this.apiEventService.publish({ type: ApiEventType.LIST_SHOP_ITEM_TYPES, status: ApiEventStatus.IN_PROGRESS });

    this.http.get(this._shopItemsApi + '/types' + '?module=' + module + '&shopReference=' + shopReference)
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.LIST_SHOP_ITEM_TYPES)))
      .subscribe((response: any) => {
        this.apiEventService.publish({ type: ApiEventType.LIST_SHOP_ITEM_TYPES, status: ApiEventStatus.COMPLETED });
        this._shopItemTypes.next(response);
      });
  }

  createShopItem(request: FormData): void {
    this.apiEventService.publish({ type: ApiEventType.ADD_PRODUCT, status: ApiEventStatus.IN_PROGRESS });

    this.http.post(this._shopItemsApi, request)
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.ADD_PRODUCT)))
      .subscribe(response => {
        this.apiEventService.publish({ type: ApiEventType.ADD_PRODUCT, status: ApiEventStatus.COMPLETED });
      })
  }

  searchShopItemByName(shopReference: string, name: string): void {
    this.apiEventService.publish({ type: ApiEventType.SEARCH_SHOP_ITEM, status: ApiEventStatus.IN_PROGRESS });

    this.http.get(this._shopItemsApi + '/search?shopReference=' + shopReference + '&name=' + name)
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.SEARCH_SHOP_ITEM)))
      .subscribe((response: any) => {
        this._searchResults.next(response);
        this.apiEventService.publish({ type: ApiEventType.SEARCH_SHOP_ITEM, status: ApiEventStatus.COMPLETED });
      })
  }

  private handleError(error: HttpErrorResponse, type: ApiEventType) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    }
    if (error.error?.code === 'CONSTRAINT_VIOLATION') {
      this.apiEventService.publish({ type: type, status: ApiEventStatus.ERROR, message: 'Request contains missing required field or data with incorrect format'});
    } else {
      this.apiEventService.publish({ type: type, status: ApiEventStatus.ERROR, message: error.error?.message});
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('An unexpected error occurred; please try again later.'));
  }
}
