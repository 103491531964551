import {Component, OnInit} from '@angular/core';
import {OAuthService} from "angular-oauth2-oidc";
import {authConfig} from "../common/auth-config";
import {JwksValidationHandler} from "angular-oauth2-oidc-jwks";
import {Router} from "@angular/router";
import {RouteService} from "../common/services/route.service";

@Component({
  selector: 'sh-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.less']
})
export class LandingComponent implements OnInit {

  constructor(private oauthService: OAuthService,
              private routeService: RouteService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        let hasIdToken = this.oauthService.hasValidIdToken();
        let hasAccessToken = this.oauthService.hasValidAccessToken();

        if (!hasIdToken || !hasAccessToken) {
          this.oauthService.initCodeFlow();
        } else {
          this.router.navigateByUrl('/portal/dashboard');
        }
      });
  }
}
