import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ShopItem, ShopItemPhoto} from "../../dto/shop-item";
import {Shop} from "../../dto/shop";
import {OrderService} from "../../services/order.service";
import {ApiEventService} from "../../services/api-event.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ApiEventStatus} from "../../dto/enums/api-event-status";
import {ApiEventType} from "../../dto/enums/api-event-type";
import {Bag} from "../../dto/bag";

@UntilDestroy()
@Component({
  selector: 'sh-shop-item-catalog',
  templateUrl: './shop-item-catalog.component.html',
  styleUrls: ['./shop-item-catalog.component.less']
})
export class ShopItemCatalogComponent implements OnInit, OnChanges {

  @Input()
  _shop: Shop | undefined;

  @Input()
  _currency: string | undefined;

  @Input()
  _shopItems: ShopItem[] = [];

  @Input()
  _showControls: boolean = false;

  @Input()
  _header: string = '';

  @Input()
  _headerSubtitle: string = '';

  @Input()
  _fixedLayout: string = '';

  _shopItemLayout = 'grid';

  _bag: Bag | undefined;

  constructor(private orderService: OrderService,
              private apiEventService: ApiEventService) {
  }

  ngOnInit(): void {
    this.subscribeToApiEvents();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this._shopItems) {
      this._shopItems.forEach((item, index) => {
        item.selected_photo = item.photos ? item.photos?.find(photo => photo.main) || item.photos[0] : undefined;
        item.featured_photos = item.photos?.slice(0, 5);
        item.rating = Math.random() * (5 - 0 + 1) + 0;

        if (index < 3) {
          item.best_seller = true;
        }
      });
    }
  }

  addToBag(item: ShopItem): void {
    this.orderService.addToBag(<number> item.id);
  }

  previewPhoto(item: ShopItem, photo: ShopItemPhoto | undefined): void {
    item.selected_photo = photo;
  }

  countStars(shop: Shop): any[] {
    return Array(Math.ceil(<number> shop.rating)).fill(shop.rating).map((x,i) => {
      if (((i + 1) % x) % 1) {
        return 0.5;
      }
      return 1;
    });
  }

  getPrice(item: ShopItem): number {
    return <number> item.prices?.find(price => price.currency === this._currency)?.regular_price;
  }

  private subscribeToApiEvents(): void {
    this.apiEventService.event
      .pipe(untilDestroyed(this))
      .subscribe(event => {
        if (event && event.status === ApiEventStatus.COMPLETED) {
          if (event.type === ApiEventType.UPDATE_BAG) {
            this.orderService.loadUserBag();
          }
        }
      });
  }
}
