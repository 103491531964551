import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {MenuItem} from "primeng/api";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService implements OnInit {

  private _items: MenuItem[] | undefined;
  private _breadcrumbs = new BehaviorSubject<MenuItem[]>([]);

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  initialize(): void {
    this._items = [];
    if (sessionStorage.getItem('breadcrumbs')) {
      this._items = <MenuItem[]> JSON.parse(<string> sessionStorage.getItem('breadcrumbs'));
    }
    this._breadcrumbs.next(this._items);
  }

  reset(): void {
    sessionStorage.removeItem('breadcrumbs');
    this.initialize();
  }

  add(route: string): void {
    if (!this._items) {
      this._items = [];
    }
    if (this._items.some(item => item.routerLink === route)) {
      this.retrace(route);
      return;
    }

    this._items.push({ label: this.getLabelByRoute(route), routerLink: route, command: () => {
        this.router.navigateByUrl(route, {skipLocationChange: true})
          .then(() => this.router.navigateByUrl(route));
      }});
    sessionStorage.setItem('breadcrumbs', JSON.stringify(this._items));
    this._breadcrumbs.next(this._items);
  }

  retrace(route: string): void {
    if (this._items && this._items.length > 1 && route) {
      let spliced = false;
      for (let i = this._items.length - 1; i > 0; i--) {
        if (this._items[i].routerLink !== route) {
          this._items.splice(i, 1);
          spliced = true;
        }
      }
      if (spliced) {
        this.router.navigateByUrl(route, {skipLocationChange: true})
          .then(() => this.router.navigateByUrl(route));
        sessionStorage.setItem('breadcrumbs', JSON.stringify(this._items));
        this._breadcrumbs.next(this._items);
      }
    }
  }

  get breadcrumbs(): Observable<MenuItem[]> {
    return this._breadcrumbs.asObservable();
  }

  private getLabelByRoute(route: string): string {
    if (<string> sessionStorage.getItem('currentShop')) {
      if (route.endsWith(<string> sessionStorage.getItem('currentShop'))) {
        return <string> sessionStorage.getItem('currentShopName');
      } else if (route.endsWith("/profile")) {
        return "Shop Profile";
      } else if (route.endsWith("/orders")) {
        return "Orders";
      } else if (route.endsWith("/warehouse/inventory")) {
        return "Inventory";
      } else if (route.endsWith("/warehouse/supplies")) {
        return "Supplies";
      } else if (route.endsWith("/production/items")) {
        return "Shop Items";
      }
    }
    return "";
  }
}
