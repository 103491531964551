import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {OAuthModule} from "angular-oauth2-oidc";
import {HeaderComponent} from "./components/header/header.component";
import {RouterLink} from "@angular/router";
import {MenubarModule} from "primeng/menubar";
import {RippleModule} from "primeng/ripple";
import {ButtonModule} from "primeng/button";
import {DropdownModule} from "primeng/dropdown";
import {ReactiveFormsModule} from "@angular/forms";
import {TableModule} from "primeng/table";
import {TooltipModule} from "primeng/tooltip";
import {MenuModule} from "primeng/menu";
import {BadgeModule} from "primeng/badge";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {PanelMenuModule} from "primeng/panelmenu";
import {LandingComponent} from "../landing/landing.component";
import { SpinnerComponent } from './components/spinner/spinner.component';
import {InputTextModule} from "primeng/inputtext";
import {TabViewModule} from "primeng/tabview";
import {RadioButtonModule} from "primeng/radiobutton";
import {environment} from "../../environments/environment";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {AutoCompleteModule} from "primeng/autocomplete";
import { OpenShopComponent } from './components/open-shop/open-shop.component';
import {DialogModule} from "primeng/dialog";
import {InputTextareaModule} from "primeng/inputtextarea";
import { ShopItemCatalogComponent } from './components/shop-item-catalog/shop-item-catalog.component';
import {DataViewModule} from "primeng/dataview";
import {AppHttpInterceptor} from "./app-http-interceptor";
import {StepsModule} from "primeng/steps";
import {CheckboxModule} from "primeng/checkbox";
import { ApiEventToasterComponent } from './components/api-event-toaster/api-event-toaster.component';
import {ToastModule} from "primeng/toast";



@NgModule({
  declarations: [
    LandingComponent,
    HeaderComponent,
    SpinnerComponent,
    OpenShopComponent,
    ShopItemCatalogComponent,
    ApiEventToasterComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.api.users, environment.api.shops, environment.api.shopItems],
        sendAccessToken: true
      }
    }),
    RouterLink,
    MenuModule,
    MenubarModule,
    RippleModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    PanelMenuModule,
    ReactiveFormsModule,
    TabViewModule,
    TableModule,
    TooltipModule,
    BadgeModule,
    ConfirmDialogModule,
    RadioButtonModule,
    OverlayPanelModule,
    AutoCompleteModule,
    DialogModule,
    InputTextareaModule,
    DataViewModule,
    StepsModule,
    CheckboxModule,
    ToastModule
  ],
  exports: [
    LandingComponent,
    HeaderComponent,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    RippleModule,
    TabViewModule,
    TableModule,
    TooltipModule,
    ConfirmDialogModule,
    RadioButtonModule,
    SpinnerComponent,
    DialogModule,
    ShopItemCatalogComponent,
    DataViewModule,
    ApiEventToasterComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    ConfirmationService,
    MessageService
  ]
})
export class SharedModule { }
