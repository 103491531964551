import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {ApiEvent} from "../dto/api-event";

@Injectable({
  providedIn: 'root'
})
export class ApiEventService {

  private _event = new BehaviorSubject<ApiEvent | undefined>(undefined);
  constructor() { }

  get event(): Observable<ApiEvent> {
    return <BehaviorSubject<ApiEvent>> this._event.asObservable();
  }

  publish(event: ApiEvent) {
    this._event.next(event);
  }
}
