<div class="p-card">
  <div class="p-card-body">
    <div class="md:flex">
      <div>
        <img src="https://cdn-icons-png.flaticon.com/512/1077/1077114.png"
             width="200" height="200">
      </div>
      <div class="md:pl-4">
        <h1>{{_currentUser}}</h1>

        <form>
          <div class="field col-12">
            <span class="p-float-label">
                <input type="email" id="firstName" pInputText>
                <label for="email">First Name</label>
            </span>
          </div>
          <div class="field col-12">
            <span class="p-float-label">
                <input type="email" id="lastName" pInputText>
                <label for="email">Last Name</label>
            </span>
          </div>
          <div class="field col-12">
            <span class="p-float-label">
                <input type="email" id="email" pInputText>
                <label for="email">Email</label>
            </span>
          </div>
          <button pButton pRipple type="submit" label="Save"></button>
        </form>
      </div>
    </div>
  </div>
</div>
