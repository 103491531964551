import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "../common/auth.guard";
import {UserComponent} from "./user.component";

const routes: Routes = [
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: UserComponent
  },
  {
    path: 'invitations',
    canActivate: [AuthGuard],
    component: UserComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
