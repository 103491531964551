import {Component, OnInit} from '@angular/core';
import {RouteService} from "../common/services/route.service";

@Component({
  selector: 'sh-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.less']
})
export class UserComponent implements OnInit {

  _activeView: string | undefined;

  constructor(private routeService: RouteService) {
  }

  ngOnInit(): void {
    if (this.routeService.endsWith('invitations')) {
      this._activeView = 'invitations';
    } else if (this.routeService.endsWith('profile')) {
      this._activeView = 'profile';
    }
  }

}
