<p-toast position="bottom-right">
  <ng-template let-message pTemplate="headless">
    <div class="row w-100 flex">
      <aside class="col-2 flex justify-content-center align-items-center toaster-icon" [ngClass]="{'success': message.severity === 'success', 'danger': message.severity === 'danger'}">
        <i class="fa-solid fa-check" *ngIf="message.severity === 'success'"></i>
        <i class="fa-solid fa-exclamation" *ngIf="message.severity === 'danger'"></i>
      </aside>
      <section class="col-10">
        <div class="mt-2 ml-2 font-bold">{{ message.summary }}</div>
        <p class="ml-2 text-dark">{{ message.detail }}</p>
      </section>
    </div>
  </ng-template>
</p-toast>
