<main class="main-grid">
  <div class="grid-header">
    <sh-header></sh-header>
  </div>
  <div class="grid-sidebar">
<!--    <se-sidebar></se-sidebar>-->
  </div>
  <main class="grid-content content-wrapper">
    <sh-profile *ngIf="_activeView === 'profile'"></sh-profile>
  </main>
</main>
