import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SharedModule} from "./common/shared.module";
import {AuthGuard} from "./common/auth.guard";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { UserComponent } from './user/user.component';
import {UserModule} from "./user/user.module";

@NgModule({
    declarations: [
        AppComponent,
        UserComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    UserModule
  ],
    providers: [
      AuthGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
