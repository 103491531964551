import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, Observable, throwError} from "rxjs";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Shop} from "../dto/shop";
import {ApiEventService} from "./api-event.service";
import {ApiEventType} from "../dto/enums/api-event-type";
import {ApiEventStatus} from "../dto/enums/api-event-status";
import {Currency} from "../dto/currency";
import {OpenShopRequest} from "../dto/open-shop-request";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ShopService {

  private _shopsApi = environment.api.shops;
  private _publicApi = environment.api.public;
  private _shops = new BehaviorSubject<Shop[]>([]);
  private _shopsForUser = new BehaviorSubject<Shop[]>([]);
  private _currencies = new BehaviorSubject<Currency[]>([]);
  private _currenciesForShop = new BehaviorSubject<Currency[]>([]);
  private _currentShop = new BehaviorSubject<Shop | undefined>(undefined);
  constructor(private http: HttpClient,
              private apiEventService: ApiEventService) { }

  get shops(): Observable<Shop[]> {
    return this._shops.asObservable();
  }

  get shopsForUser(): Observable<Shop[]> {
    return this._shopsForUser.asObservable();
  }

  get currentShop(): Observable<Shop> {
    return (<BehaviorSubject<Shop>> this._currentShop).asObservable();
  }

  get currencies(): Observable<Currency[]> {
    return this._currencies.asObservable();
  }

  get shopCurrencies(): Observable<Currency[]> {
    return this._currenciesForShop.asObservable();
  }

  openNewShop(request: OpenShopRequest) {
    this.apiEventService.publish({type: ApiEventType.OPEN_SHOP, status: ApiEventStatus.IN_PROGRESS});
    this.http.post(this._shopsApi, request)
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.OPEN_SHOP)))
      .subscribe((response: any) => {
        this.apiEventService.publish({type: ApiEventType.OPEN_SHOP, status: ApiEventStatus.COMPLETED, message: 'The shop "' + request.name + '" was successfully created'});
      });
  }

  updateShop(request: Shop) {
    this.apiEventService.publish({type: ApiEventType.UPDATE_SHOP, status: ApiEventStatus.IN_PROGRESS});
    this.http.put(this._shopsApi + "/" + request.reference, request)
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.UPDATE_SHOP)))
      .subscribe((response: any) => {
        this.apiEventService.publish({type: ApiEventType.UPDATE_SHOP, status: ApiEventStatus.COMPLETED, message: 'Shop profile was successfully updated'});
      });
  }

  selectShop(shopReference: string) {
    this.apiEventService.publish({type: ApiEventType.SELECT_SHOP, status: ApiEventStatus.IN_PROGRESS});
    this.http.get(this._shopsApi + '/' + shopReference)
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.SELECT_SHOP)))
      .subscribe((response: any) => {
        this.apiEventService.publish({type: ApiEventType.SELECT_SHOP, status: ApiEventStatus.COMPLETED});
        this._currentShop.next(response);
        if (response) {
          sessionStorage.setItem('currentShop', response.reference);
          sessionStorage.setItem('currentShopName', response.name);
        }
      });
  }

  listAllActiveShops(currency: string) {
    this.apiEventService.publish({type: ApiEventType.LIST_ACTIVE_SHOPS, status: ApiEventStatus.IN_PROGRESS});
    this.http.get(this._shopsApi + '?status=ACTIVE&currency=' + currency)
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.LIST_ACTIVE_SHOPS)))
      .subscribe((response: any) => {
        this.apiEventService.publish({type: ApiEventType.LIST_ACTIVE_SHOPS, status: ApiEventStatus.COMPLETED});
        this._shops.next(response);
      });
  }

  listActiveAffiliatedShops() {
    this.apiEventService.publish({type: ApiEventType.LIST_ACTIVE_AFFILIATED_SHOPS, status: ApiEventStatus.IN_PROGRESS});
    this.http.get(this._shopsApi + '/affiliates?status=ACTIVE')
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.LIST_ACTIVE_AFFILIATED_SHOPS)))
      .subscribe((response: any) => {
        this.apiEventService.publish({type: ApiEventType.LIST_ACTIVE_AFFILIATED_SHOPS, status: ApiEventStatus.COMPLETED});
        this._shopsForUser.next(response);
      });
  }

  listCurrencies() {
    this.apiEventService.publish({type: ApiEventType.LIST_CURRENCIES, status: ApiEventStatus.IN_PROGRESS});
    this.http.get(this._publicApi + '/currencies')
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.LIST_CURRENCIES)))
      .subscribe((response: any) => {
        this.apiEventService.publish({type: ApiEventType.LIST_CURRENCIES, status: ApiEventStatus.COMPLETED});
        this._currencies.next(response);
      });
  }

  listShopCurrencies() {
    this.apiEventService.publish({type: ApiEventType.LIST_SHOP_CURRENCIES, status: ApiEventStatus.IN_PROGRESS});
    this.http.get(this._shopsApi + '/' + sessionStorage.getItem('currentShop') + '/currencies')
      .pipe(untilDestroyed(this),
        catchError(err => this.handleError(err, ApiEventType.LIST_SHOP_CURRENCIES)))
      .subscribe((response: any) => {
        this.apiEventService.publish({type: ApiEventType.LIST_SHOP_CURRENCIES, status: ApiEventStatus.COMPLETED});
        this._currenciesForShop.next(response);
      });
  }

  private handleError(error: HttpErrorResponse, type: ApiEventType) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    }
    if (error.error?.code === 'CONSTRAINT_VIOLATION') {
      this.apiEventService.publish({ type: type, status: ApiEventStatus.ERROR, message: 'Request contains missing required field or data with incorrect format'});
    } else {
      this.apiEventService.publish({ type: type, status: ApiEventStatus.ERROR, message: error.error?.message});
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('An unexpected error occurred; please try again later.'));
  }
}
