import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {ApiEventService} from "./api-event.service";
import {ApiEventType} from "../dto/enums/api-event-type";
import {User} from "../dto/user";
import {ApiEventStatus} from "../dto/enums/api-event-status";


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _usersApi = environment.api.users;

  private _currentUser = new BehaviorSubject<User | undefined>(undefined);

  constructor(private http: HttpClient,
              private apiEventService: ApiEventService) { }

  get currentUser(): Observable<User | undefined> {
    return this._currentUser.asObservable();
  }

  loadCurrentUser(): void {
    this.apiEventService.publish({type: ApiEventType.LOAD_CURRENT_USER, status: ApiEventStatus.IN_PROGRESS});
    this.http.get(this._usersApi + '/my-profile')
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.apiEventService.publish({type: ApiEventType.LOAD_CURRENT_USER, status: ApiEventStatus.COMPLETED});
        this._currentUser.next(response);
      });
  }
}
